<template>
  <v-row no-gutters>
    <v-data-table
      v-if="backendVersionCount"
      :footer-props="itemsPerPageOptions"
      :headers="headers"
      :items="versions"
      :items-per-page.sync="itemsPerPage"
      :page.sync="page"
      :search="search"
      :server-items-length="backendVersionCount"
      :sort-by.sync="sort"
      :sort-desc.sync="desc"
      class="flex"
    >
      <template v-slot:top>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="labels.search"
          single-line
          hide-details
          :disabled="loading"
        />
      </template>
      <template v-slot:item.provider="{ value }">
        <v-chip dark color="primary">{{ value }}</v-chip>
      </template>

      <template v-slot:item.image="{ item, value }">
        <v-btn
          plain
          color="primary"
          :to="{
            name: 'game-edit',
            params: { provider: item.provider, id: environment.id, image: item.image },
          }"
          class="flat-btn"
        >
        <!-- <a :href="getGameUrl(...(value.split('_').slice(-2)))"> -->
          {{ value.split('_')[2] }}
        </v-btn>
      </template>

      <template v-slot:item.installed_backends="{ item }">
        <div
          v-for="(version, index) in item.installed_backends"
          :key="version"
        >
          <v-chip
            dark small
            color="primary"
            class="mx-1"
          >
            {{ version }}
          </v-chip>
          <v-chip
            v-if="item.installed_frontends[index]"
            dark small
            color="green"
            class="mr-1"
          >
            FE: {{ item.installed_frontends[index] }}
          </v-chip>
        </div>
      </template>
    </v-data-table>
    <v-skeleton-loader loading v-else type="table" class="flex" />
  </v-row>
</template>

<script>
/* eslint-disable no-param-reassign */
/* eslint prefer-template: "off" */
import { debounce, isEmpty } from 'lodash';
import { mapActions, mapState } from 'vuex';
import { labels } from '@/assets/texts.json';

export default {
  name: 'gameInstallTable',
  beforeDestroy() {
    this.clearInterval();
  },
  created() {
    // this.clear('backendVersionCount');
    this.getData();
    this.setTimerMethod(this.getData);
    this.setInterval();
  },
  computed: {
    ...mapState('fetch', [
      'environment',
      'backendVersionCount',
      'backendVersions',
      'gameInstances',
      'permissions',
    ]),
    ...mapState('shared', [
      'loading',
      'refresh',
    ]),
    canCreatePromotions() {
      return (
        this.permissions.game_promotion_create
        && !isEmpty(this.environment.allowed_promotions)
      );
    },
  },
  data: () => ({
    itemsPerPage: 20,
    itemsPerPageOptions: { 'items-per-page-options': [20, 50, -1] },
    page: 1,
    search: null,
    sort: 'image',
    desc: false,
    headers: [
      {
        text: 'Provider',
        value: 'provider',
      },
      {
        text: 'Game',
        value: 'image',
      },
      {
        text: 'Installed Versions',
        value: 'installed_backends',
        sortable: false,
      },
    ],
    labels,
    versions: [],
  }),
  methods: {
    ...mapActions({
      clear: 'fetch/clear',
      clearInterval: 'fetch/clearInterval',
      fetchVersions: 'fetch/fetchVersions',
      setInterval: 'fetch/setInterval',
      setTimerMethod: 'fetch/setTimerMethod',
    }),
    getData() {
      const params = {
        items_per_page: this.itemsPerPage,
        unique: true,
        page: this.page,
        ...(!isEmpty(this.search) && { query: this.search }),
        sort_by: this.sort,
        desc: this.desc,
      };
      this.fetchVersions({ backend: true, params }).then(() => {
        this.backendVersions.forEach((bev) => {
          const installed = this.gameInstances.filter(
            (g) => bev.image === `gls_${g.provider}_${g.name}`,
          );
          if (!isEmpty(installed)) {
            bev.installed_backends = installed.map(
              (g) => g.game_backend_full_version,
            );
            bev.installed_frontends = installed.map(
              (g) => g.game_frontend_version,
            );
          } else {
            bev.installed_backends = null;
            bev.installed_frontends = null;
          }
        });
        this.versions = this.backendVersions;
      });
    },
    loadData() {
      this.$nextTick(() => { // wait for sortBy/desc to get updated
        this.getData();
      });
    },
    updateDataTable() {
      this.loadData();
      if (this.refresh) { // update the timer method
        this.clearInterval();
        this.setInterval();
      }
    },
  },
  watch: {
    search: debounce(function debouncedFetch() {
      this.page = 1;
      this.loadData();
    }, 500), // ms
    sort: function sort() {
      this.updateDataTable();
    },
    desc: function desc() {
      this.updateDataTable();
    },
    page: function page() {
      this.updateDataTable();
    },
  },
};
</script>

<style scoped>
  .flat-btn {
    text-transform: none;
  }
</style>
