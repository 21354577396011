var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"no-gutters":""}},[(_vm.backendVersionCount)?_c('v-data-table',{staticClass:"flex",attrs:{"footer-props":_vm.itemsPerPageOptions,"headers":_vm.headers,"items":_vm.versions,"items-per-page":_vm.itemsPerPage,"page":_vm.page,"search":_vm.search,"server-items-length":_vm.backendVersionCount,"sort-by":_vm.sort,"sort-desc":_vm.desc},on:{"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event},"update:page":function($event){_vm.page=$event},"update:sortBy":function($event){_vm.sort=$event},"update:sort-by":function($event){_vm.sort=$event},"update:sortDesc":function($event){_vm.desc=$event},"update:sort-desc":function($event){_vm.desc=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.labels.search,"single-line":"","hide-details":"","disabled":_vm.loading},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.provider",fn:function(ref){
var value = ref.value;
return [_c('v-chip',{attrs:{"dark":"","color":"primary"}},[_vm._v(_vm._s(value))])]}},{key:"item.image",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('v-btn',{staticClass:"flat-btn",attrs:{"plain":"","color":"primary","to":{
          name: 'game-edit',
          params: { provider: item.provider, id: _vm.environment.id, image: item.image },
        }}},[_vm._v(" "+_vm._s(value.split('_')[2])+" ")])]}},{key:"item.installed_backends",fn:function(ref){
        var item = ref.item;
return _vm._l((item.installed_backends),function(version,index){return _c('div',{key:version},[_c('v-chip',{staticClass:"mx-1",attrs:{"dark":"","small":"","color":"primary"}},[_vm._v(" "+_vm._s(version)+" ")]),(item.installed_frontends[index])?_c('v-chip',{staticClass:"mr-1",attrs:{"dark":"","small":"","color":"green"}},[_vm._v(" FE: "+_vm._s(item.installed_frontends[index])+" ")]):_vm._e()],1)})}}],null,false,3534483920)}):_c('v-skeleton-loader',{staticClass:"flex",attrs:{"loading":"","type":"table"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }